import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useReducer,
} from "react";
import { IIronman } from "../interfaces/IIronman";
const UserContext = createContext<IUserInfo>({});

const useUserContext = () => {
  return useContext(UserContext);
};
interface IProps {
  children: ReactNode;
}
interface IUserInfo {
  user?: IIronman.GetUserInformation.Response;
  dispatch?: Dispatch<any>;
}
const initialState: IUserInfo = {
  user: undefined,
};
const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "USER_FOUNDED": {
      return {
        ...state,
        user: action.value,
      };
    }
    case "USER_REMOVED": {
      return {
        ...state,
        user: undefined,
      };
    }
  }
  return state;
};
const UserProvider: FC<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={{ ...state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
export { UserProvider, useUserContext };
